import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from "components/site-structure/layout"
import SEOComponent from "components/seo"

const Regeln = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "mölkky-aufbau-schema.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 1000
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <SEOComponent
        description="Spielregeln für Mölkky"
        title="Mölkky Spielregeln"
      />

      <div className="container">
        <h1 className="title is-1">Regeln</h1>

        <div className="columns">
          <div className="column is-one-quarter">
            <aside className="menu ml-20">
              <p className="menu-label">Übersicht</p>
              <ul className="menu-list">
                <li>
                  <a href="#was-ist-möllky">Was ist Möllky?</a>
                </li>
              </ul>
              <p className="menu-label">Spielregeln</p>
              <ul className="menu-list">
                <li>
                  <a href="#vorbereitungen">Spielvorbereitungen</a>
                </li>
                <li>
                  <a href="#aufbau">Spielaufbau</a>
                </li>
                <li>
                  <a href="#ablauf">Ablauf</a>
                </li>
                <li>
                  <a href="#punktwertung">Punktwertung</a>
                </li>
                <li>
                  <a href="#ziel-des-spiels">Ziel des Spiels</a>
                </li>
              </ul>
              <p className="menu-label">Sonderregeln</p>
              <ul className="menu-list">
                <li>
                  <a href="#einleitung-sonderregeln">Einleitung Sonderregeln</a>
                </li>
                <li>
                  <a href="#duschen">Duschen</a>
                </li>
                <li>
                  <a href="#ueberwerfen">Überwerfen</a>
                </li>
              </ul>
            </aside>
          </div>

          <div className="column">
            <div className="section" id="was-ist-mölkky">
              <h2 className="title is-5">Was ist Mölkky?</h2>
              <p>
                Mölkky ist ein Finnisches Wurf-Spiel, bei dem man versucht,
                durch Umwerfen von Punkte-Hölzern mit dem Wurfholz (dem sog.
                "Mölkky"), möglichst als erster 50 Punkte zu erreichen.
              </p>
            </div>

            <div className="section" id="vorbereitungen">
              <h2 className="title is-5">Spielvorbeireitungen</h2>
              <p>
                Als erstes wird eine Leine von ca. 2-3 Metern gespannt. Das ist
                die Abwurflinie. Das Seil bildet eine Box, die man beim Abwurf
                zu <strong>keiner</strong> Seite verlassen darf.
              </p>
            </div>

            <div className="section" id="aufbau">
              <h2 className="title is-5">Spielaufbau</h2>
              <p>
                Nun werden die Punktehölzer mittig orthogonal zur Abwurflinie in
                ca. 3 Metern Entfernung (je nach Erfahrungsstand der Spieler
                variierbar) aufgestellt. Das Muster ist hierbei:
              </p>
              <GatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                alt="Mölkky Aufbau Schematik"
                title="Mölkky Aufbau Schematik"
              />
            </div>

            <div className="section" id="ablauf">
              <h2 className="title is-5">Spielablauf</h2>
              <p>
                Nachdem alle Spieler in die Spielerliste eingetragen sind,
                beginnt der Erste. (Je nachdem, in welcher Reihenfolge sie
                anfangs auf der Liste stehen.) Der Spieler wirft mit dem
                Wurfholz (dem Mölkky) aus der Wurfzone (hinter der Abwurflinie)
                auf die Punkte-Klötze. Nachdem alle Klötze zum erliegen gekommen
                sind, <a href="#punktwertung">werden die Punkte gewertet. </a>
                Anschließend baut der Werfer alle gefallenen Klötze wieder an
                der Stelle auf, an der sie liegen geblieben sind und der nächste
                Spieler ist an der Reihe. Nach einer kompletten Runde, also
                nachdem jeder Spieler einmal geworfen hat, beginnt der Spieler
                mit der aktuell niedrigsten <b>Gesamt-</b>Punktzahl die neue
                Runde. Zweiter Werfer ist derjenige mit der zweit-niedrigsten{" "}
                <b>Gesamt-</b>Punktzahl usw.
              </p>
            </div>

            <div className="section" id="punktwertung">
              <h2 className="title is-5">Punktwertung</h2>
              <p>
                <dl>
                  <li>
                    Wirft ein Spieler <b>einen</b> Punkteklotz um, so zählen die
                    Punkte, die <b>auf dem Klotz stehen.</b>
                  </li>
                  <li>
                    Wirft ein Spieler <b>mehrere</b> Punktklötze um, so zählt
                    die <b>Anzahl</b> der Klötze, die <b>vollständig</b> am
                    Boden liegen. Liegen zwei Klözte übereinander, sodass nur
                    einer der beiden den Boden vollständig berührt, so zählt nur
                    dieser Klotz als "umgeworfen".
                  </li>
                </dl>
              </p>
              <br />
              <article className="message is-info">
                <div className="message-header">
                  <p>Beispiel:</p>
                </div>
                <div className="message-body">
                  <p>
                    Georg wirft und trifft <b>nur</b> den Klotz mit der 9. Alle
                    andern Klötze bleiben stehen. Georg bekommt 9 Punkte.
                  </p>
                  <hr />
                  <p>
                    Jörg wirft und trifft die Klötze mit der 8 und der 10,
                    allerdings fallen sie so, dass der Klotz mit der 10{" "}
                    <b>über</b> dem mit der 8 pegen bleibt und somit den Boden
                    nicht vollständig berührt. Jörg erhält 8 Punkte.
                  </p>
                  <hr />
                  <p>
                    Martin wirft und trifft die 11 und die 12, beide fallen um
                    und berühren den Boden vollständig. Martin bekommt dafür 2
                    Punkte.
                  </p>
                </div>
              </article>
            </div>

            <div className="section" id="ziel-des-spiels">
              <h2 className="title is-5">Ziel des Spiels</h2>
              <p>
                Gewonnen hat, wer <b>genau</b> 50 Punkte erreicht. Es kann
                mehrere Gewinner geben!
              </p>
            </div>

            <div className="section" id="einleitung-sonderregeln">
              <h2 className="title is-5">Einleitung Sonderregeln</h2>
              <p>
                Für Mölkky gibt es viele Sonderregeln.{" "}
                <Link to={"/spielen/"}> Die kostenlose Mölkky Online App </Link>{" "}
                folgt diesen Regeln:
              </p>
            </div>

            <div className="section" id="duschen">
              <h2 className="title is-5">Sonderregel 1: Duschen</h2>
              <p>
                Wer <strong>dreimal</strong> hintereinander{" "}
                <strong>keine</strong> Punkte erzielen kann, scheidet aus dem
                Wettbewerb aus. Er oder sie geht also duschen!
              </p>
            </div>

            <div className="section" id="ueberwerfen">
              <h2 className="title is-5">Sonderregel 2: Überwerfen</h2>
              <p>
                Wer nach seinem Wurf mehr als 50 Punkte hat, hat sich
                "überworfen".
              </p>
              <br />
              <article className="message is-info">
                <div className="message-header">
                  <p>Beispiel:</p>
                </div>
                <div className="message-body">
                  Niklas hat aktuell 45 Punkte, braucht also noch genau 5 zum
                  gewinnen. Er wirft allerdings 10 Punkte, hat nun also 55 und
                  somit <strong>mehr</strong> als 50. Er wird auf{" "}
                  <strong>25</strong> Punkte zurückgesetzt.
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Regeln
